import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Action, Getter, State as StateClass } from 'vuex-class';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import FormInput from '@/components/common/form-elements/FormInput.vue';
import { State } from '@/store/models';
import { Investor, PrivateInvestor } from '@/store/models/user';
import { Asset } from '@/store/models/asset';
import * as MUTATIONS from '@/store/constants';

@Component({
  components: {
    FormInput,
    ValidationProvider,
    ValidationObserver,
  },
})

export default class PropertyReservationModal extends Vue {
  @Prop() asset!: Asset;

  name: string = ''
  email: string = ''
  telephone: string = ''
  amount: string = ''

  @StateClass user!: State['user']
  @StateClass operations!: State['operations'];

  @Action assetReservation!: Function
  @Action(addToastMessage) addToastMessage!: Function;

  @Getter isInvestor!: boolean
  @Getter isUser!: boolean
  @Getter isUserLoggedIn!: boolean

  handleReservation(): void {
    try {
      this.$gtm.trackEvent({
        event: 'add_to_wishlist',
        user: this.user?.id,
        email: this.user?.email,
        transactionTotal: this.amount,
        transactionProducts: [{
          content_type: 'Product',
          sku: this.asset?.id,
          name: this.asset?.name,
          price: this.asset?.sharePrice,
          currency: 'EUR',
        }],
      });
    } catch (e) { /* Silent error */ }

    this.assetReservation({
      asset: this.asset,
      name: this.formattedName,
      email: this.user?.email || this.email,
      telephone: (this.user as PrivateInvestor)?.telephone || this.telephone,
      amount: this.amount,
    });
  }

  @Watch('operations')
  onOperationsChange(newOperation: any, oldOperation: any): void {
    if (newOperation.name === MUTATIONS.ASSET_RESERVATION_ERROR
      && oldOperation.name === MUTATIONS.ASSET_RESERVATION_PROCESSING) {
      this.addToastMessage({
        text: this.$t('property.reservation.error'),
        type: 'danger',
      });
    } else if (newOperation.name === MUTATIONS.ASSET_RESERVATION_SUCCESS
      && oldOperation.name === MUTATIONS.ASSET_RESERVATION_PROCESSING) {
      this.$modal.hide('propertyReservationModal');
      this.$modal.show('propertyReservationResultModal');
      this.resetForm();
    }
  }

  resetForm(): void {
    const fields = ['name', 'email', 'telephone', 'amount'];

    fields.forEach((field): void => {
      this[field] = '';
    });
  }

  get formattedName(): string {
    const name = (this.user as Investor)?.name;
    const surname = (this.user as Investor)?.surname;

    return name ? `${name}${surname ? ` ${surname}` : ''}` : this.name;
  }

  get submittingPropertyReservation(): boolean {
    return this.operations && this.operations.name === MUTATIONS.ASSET_RESERVATION_PROCESSING;
  }
}
